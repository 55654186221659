import React, { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_KEY}`)
  }
  return stripePromise
}

export default function Pay () {
  const [loading, setLoading] = useState(true)

  const showStuff = () => {
    if (loading) {
      return (
        <h1>loading...</h1>
      )
    } else {
      return (
        <h1>redirecting...</h1>
      )
    }
  }

  const checkOut = async () => {
    const stripe = await getStripe()

    fetch('https://striper-server.herokuapp.com/prep-course', {
      method: 'POST',
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(session) {
        setLoading(false)
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function(result) {
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function(error) {
        console.error('Error:', error);
      });
  }

  checkOut();

  return showStuff()
}